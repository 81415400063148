import React from 'react';


const Footer = () => (
  <div style={{ height: '100px', width: '100%' }}>

  </div>

);

export default Footer;
