export const images = [
  {
    src: '/Thumbnail_0001.jpg'
  },
  {
    src: '/Thumbnail_0002.jpg'
  },
  {
    src: '/Thumbnail_0003.jpg'
  },
  {
    src: '/Thumbnail_0004.jpg'
  },
  {
    src: '/Thumbnail_0005.jpg'
  },
  {
    src: '/Thumbnail_0006.jpg'
  },
  {
    src: '/Thumbnail_0007.jpg'
  },
  {
    src: '/Thumbnail_0008.jpg'
  },
  {
    src: '/Thumbnail_0009.jpg'
  },
  {
    src: '/Thumbnail_0010.jpg'
  },
  {
    src: '/Thumbnail_0011.jpg'
  },
  {
    src: '/Thumbnail_0012.jpg'
  },
  {
    src: '/Thumbnail_0013.jpg'
  },
  {
    src: '/Thumbnail_0014.jpg'
  },
  {
    src: '/Thumbnail_0015.jpg'
  },
  {
    src: '/Thumbnail_0016.jpg'
  },
  {
    src: '/Thumbnail_0017.jpg'
  },
  {
    src: '/Thumbnail_0018.jpg'
  },
  {
    src: '/Thumbnail_0019.jpg'
  },
  {
    src: '/Thumbnail_0020.jpg'
  },
  {
    src: '/Thumbnail_0021.jpg'
  },
  {
    src: '/Thumbnail_0022.jpg'
  },
  {
    src: '/Thumbnail_0023.jpg'
  },
  {
    src: '/Thumbnail_0024.jpg'
  }

];
